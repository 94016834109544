<template>
    <div class="more_game">
        <Top  name="游戏官网" email back></Top>
        <van-search
            v-model="value"
            show-action
            placeholder="请输入关键词搜索"
            @search="onSearch"
        >
        <template #action>
            <div @click="onSearch" class="orange">搜索</div>
        </template>
        </van-search>
        <ul class="moreGame">
            <van-skeleton title :row="3"  :loading="loading" />
            <li v-for="(item, index) in gameList" :key="index">
                <a :href="item.url" target="_blank" style="display: block;" @click="getGameId(item.channel_id)">
                    <div class="moreGame_bg">
                        <img :src="item.imgSrc" alt="">
                    </div>
                    <div class="moreGame_name"><span>{{item.name}}</span></div>
                </a>
            </li>
            <van-empty description="暂无数据" v-if="gameList.length==0&&!loading"/>
        </ul>
    </div>
</template>
<script>
import Top from '@/components/top'
import base from '@/api/base';
export default {
    name:"moreGame",
    data(){
        return {
            value:'',
            gameList: [],
            loading: true
        }
    },
    components: {
      Top
    },
    created() {
        //获取游戏渠道列表数据
        this.gameChannelList('');
    },
    methods:{
        //搜索
        onSearch(){
            this.gameChannelList(this.value);
        },
        //游戏渠道列表
        gameChannelList(channel_name) {
            let data = {
                channel_name: channel_name,
                dev: localStorage.getItem('ip')
            };
            //清空列表
            this.gameList = [];
            this.$api.shopping.gameChannelList(data).then(res => {
                if (res.code == 200) {
                    this.loading = false;
                    if (res.data.length > 0) {
                        //游戏头像展示
                        res.data.forEach((item,index)=>{
                            if(item.channel_id==24){
                                res.data.splice(index,1)
                            }
                        })
                        res.data.forEach((item) => {
                            item.name = item.channel_name;
                            item.url = item.web_url;
                            item.channel_id = item.channel_id;
                            if(item.img_url){
                                item.imgSrc = base.bd+'/'+item.img_url
                            }else{
                                switch (item.channel_id) {
                                    case 0:
                                        item.imgSrc = require('../assets/images/more_heard1.png');
                                        break;
                                    case 5:
                                        item.imgSrc = require('../assets/images/more_heard4.png');
                                        break;
                                    case 6:
                                        item.imgSrc = require('../assets/images/more_heard2.png');
                                        break;
                                    case 9:
                                        item.imgSrc = require('../assets/images/more_heard3.png');
                                        break;
                                }
                            }
                            
                            this.gameList.push(item);
                        });
                    }else{
                        this.gameList = res.data;
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //点击游戏logo
        getGameId(channel_id) {
            if (localStorage.getItem('token')) {
                this.$api.shopping.promote({
                    click_channel_id: channel_id
                }).then();
            }
        }
    }
}
</script>



<style scoped>
.van-search__content{
    border-radius: 20px;
}
.van-search__action{
    color: #0072ff;
    padding-right: 20px;
}
.moreGame{
    display: flex;
    padding: .75rem 2.40625rem;
    border-top: 1px solid #bbbbbb;
    justify-content: space-between;
    flex-wrap: wrap;
}
.moreGame li{
    width: 5.125rem;
    
}
.moreGame li .moreGame_bg{
    width: 5.125rem;
    height: 5.125rem;
    background-image: url('../assets/images/more_game_bg.png');
    background-size: 100% 100%;
    position: relative;
}
.moreGame li .moreGame_bg img{
    width: 3.75rem;
    height: 3.75rem;
    position: absolute;
    top: 14%;
    left: 14%;
    border-radius: 50%;
}
.moreGame li .moreGame_name{
    width: 3.4375rem;
    height: 2.71875rem;
    margin: 0 auto;
    position: relative;
}
.moreGame li .moreGame_name span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}
.moreGame .van-empty{
    margin: 0 auto;
}
</style>